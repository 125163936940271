<template>
  <div>
    <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni ni-books"></i> Laporan - Perjam
      </h6>
    </h1>

    <base-header class="pb-6">
      <b-row>
        <b-col xl="12" md="12">
          <b-card style="position: relative; overflow: hidden">
            <h3>Cari Berdasarkan</h3>

            <div
              class="d-flex justify-content-start"
              style="position: relative; z-index: 999"
            >
              <!-- status pendaftaran -->
              <!-- <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                  <label><small>Status Pendaftaran</small></label>
                  <el-select class="select-primary pagination-select" v-model="filterStatusPendaftaran"  placeholder="Status Pendaftaran">
                  <el-option
                      class="select-primary"
                      v-for="item in selectOptionStatus"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                  >
                  </el-option>
                  </el-select>
                </div> -->

              <!-- Tanggal -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
              >
                <label><small>Tanggal</small></label>
                <base-input name="Tanggal">
                  <flat-picker
                    placeholder="Filter Tanggal"
                    :config="provideSelect.flatPickerConfig"
                    class="form-control datepicker"
                    v-model="userInput.tanggal"
                    style="background: white"
                  />
                </base-input>
              </div>

            

              <!-- Jenis Industri -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
                v-if="log_as == 'admin'"
              >
                <label><small>Jenis Industri</small></label>
                <base-input>
                  <el-select
                    filterable
                    placeholder="Jenis Industri"
                    v-model="userInput.companyType"
                    @change=companyTypeChange()
                  >
                    <el-option value="">Semua Jenis Industri</el-option>
                    <el-option
                      v-for="(option, idx) in provideSelect.companyType"
                      :key="idx"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <!-- Provinsi -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
                v-if="log_as == 'admin'"
              >
                <label><small>Provinsi</small></label>
                <base-input name="Provinsi">
                  <el-select
                    v-model="form.umum.compProvince"
                    @change="getCityDepentOnProvince(form.umum.compProvince)"
                    filterable
                  >
                    <el-option label="Semua Provinsi" :value="null">
                    </el-option>
                    <el-option
                      v-for="option in provideSelect.provinsi"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
                v-if="log_as == 'admin'"
              >
                <label><small>Kabupaten / Kota</small></label>
                <base-input name="Kabupaten / Kota">
                  <el-select v-model="form.umum.compCity" @change="kabkotChange()" filterable>
                    <el-option
                      v-for="option in provideSelect.kabupatenKota"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <!-- Kabupaten kota -->
              <!-- <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota">
                    <select
                      class="form-control"
                      v-model="form.umum.compCity"
                    >
                      <option
                        v-for="(option, idx) in provideSelect.kabupatenKota"
                        :key="idx"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </base-input>
                </div> -->

              <!-- Nama Industri -->
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  width: 180px;
                  margin-right: 10px;
                "
                    v-if="log_as == 'admin'"

              >
                <label><small>Nama Industri</small></label>
                <base-input name="Nama Industri">
                  <el-select
                    v-model="userInput.companyName"
                    @change="getMyCompliencePoint"
                    filterable
                  >
                    <el-option value="">Semua</el-option>
                    <el-option
                      v-for="option in provideSelect.companyName"
                      :key="option.id"
                      :label="option.name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>

              <!-- Titik Penaatan -->
              <div style="display: flex; flex-direction: column; width: 180px">
                <label><small>Titik Penaatan</small></label>
                <base-input>
                  <el-select
                    filterable
                    placeholder="Titik Penaatan"
                    v-model="userInput.titikPenaatan"
                  >
                    <el-option value="">Semua</el-option>
                    <el-option
                      v-for="option in listDataCompliencePoints"
                      :key="option.id"
                      :label="option.spot_name"
                      :value="option.id"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <base-button size="md" type="primary" @click="getBaseOnStatus">
              Tampilkan
            </base-button>
            <img
              src="img/brand/klhk.png"
              alt=""
              style="
                position: absolute;
                right: 0;
                top: -100px;
                z-index: 50;
                opacity: 0.1;
              "
            />
          </b-card>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <b-row>
                <b-col lg="6" cols="7"> </b-col>

                <b-col
                  lg="6"
                  cols="7"
                  style="display: flex; justify-content: flex-end"
                >
                  <button
                    class="btn text-white"
                    style="background-color: #b51308"
                    @click="downloadPDFNow"
                  >
                    <i
                      class="fa fa-solid fa-file-pdf mr-2"
                      style="font-size: 20px"
                    ></i>
                    Unduh PDF
                  </button>
                  <button
                    class="btn text-white"
                    style="background-color: #0a7641"
                    @click="downloadExcelNow"
                  >
                    <i
                      class="fa fa-solid fa-file-excel mr-2"
                      style="font-size: 20px"
                    ></i>
                    Unduh Excel
                  </button>
                </b-col>
              </b-row>
            </template>
            <div>
              <section id="mytable">
                <el-table
                  :data="listData"
                  row-key="timestamp"
                  header-row-class-name="thead-light"
                >
                  <el-table-column label="No" type="index" width="100" />

                  <el-table-column
                    min-width="180px"
                    label="Nama Industri"
                    v-if="log_as == 'admin'"
                  >
                    <div slot-scope="action">
                      {{ action.row.comp_name }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="120px" label="Titik Penaatan">
                    <div slot-scope="action">
                      {{ action.row.spot_name }}
                    </div>
                  </el-table-column>

                  <el-table-column
                    min-width="200px"
                    label="Jenis Industri"
                    v-if="log_as == 'admin'"
                  >
                    <div slot-scope="action">
                      {{ action.row.comp_type_name }}
                    </div>
                  </el-table-column>

                  <el-table-column
                    min-width="180px"
                    label="Provinsi"
                    v-if="log_as == 'admin'"
                  >
                    <div slot-scope="action">
                      {{ action.row.province_name }}
                    </div>
                  </el-table-column>

                  <el-table-column
                    min-width="180px"
                    label="Kabupaten / Kota"
                    v-if="log_as == 'admin'"
                  >
                    <div slot-scope="action">
                      {{ action.row.kabkot_name }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="120px" label="dibuat">
                    <div slot-scope="action">
                      {{ formatDate(action.row.hourly_timestamp)  }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="120px" label="Ph" v-if="!noPh">
                    <div slot-scope="action">
                      {{
                        action.row.ph
                          ? action.row.ph.toFixed(5)
                          : "Tidak Diwajibkan"
                      }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="120px" label="COD (MG/L)" v-if="!noCod" >
                    <div slot-scope="action">
                      {{
                        action.row.cod
                          ? action.row.cod.toFixed(5)
                          : "Tidak Diwajibkan"
                      }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="120px" label="TSS (MG/L)" v-if="!noTss">
                    <div slot-scope="action">
                      {{
                        action.row.tss
                          ? action.row.tss.toFixed(5)
                          : "Tidak Diwajibkan"
                      }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="130px" label="NH3N (MG/L)" v-if="!noNh3n">
                    <div slot-scope="action">
                      {{
                        action.row.nh3n
                          ? action.row.nh3n.toFixed(5)
                          : "Tidak Diwajibkan"
                      }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="125px" label="DEBIT (MG3/MENIT)" v-if="!noDebit">
                    <div slot-scope="action">
                      {{
                        action.row.debit
                          ? action.row.debit.toFixed(5)
                          : "Tidak Diwajibkan"
                      }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="120px" label="BEBAN COD (KG/JAM)" v-if="!noBebanCod">
                    <div slot-scope="action">
                      {{
                        action.row.beban_cod
                          ? action.row.beban_cod.toFixed(5)
                          : "Tidak Diwajibkan"
                      }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="120px" label="BEBAN TSS (KG/JAM)" v-if="!noBebanTss">
                    <div slot-scope="action">
                      {{
                        action.row.beban_tss
                          ? action.row.beban_tss.toFixed(5)
                          : "Tidak Diwajibkan"
                      }}
                    </div>
                  </el-table-column>

                  <el-table-column
                    min-width="120px"
                    label="BEBAN NH3N (KG/JAM)"
                    v-if="!noBebanNh3n"
                  >
                    <div slot-scope="action">
                      {{
                        action.row.beban_nh3n
                          ? action.row.beban_nh3n.toFixed(5)
                          : "Tidak Diwajibkan"
                      }}
                    </div>
                  </el-table-column>

                

                  <!-- <el-table-column min-width="250px"  label="">
                    <div slot-scope="action">
                        <base-button
                        @click="view(action.row.id)"
                        type="secondary"
                        size="sm"
                        icon
                        >
                        RINCIAN
                        </base-button>
                        <base-button
                        @click="handleEdit(action.row)"
                        type="warning"
                        size="sm"
                        icon
                        >
                        RUBAH DATA
                        </base-button>
                    </div>
                  </el-table-column> -->
                </el-table>

                <div class="py-3">
                  <el-select
                    class="select-primary pagination-select mr-3"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    @change="changeSizePage()"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <small class="card-category">
                    Showing {{ 1 }} to {{ listData.length }} of
                    {{ pagination.total }} entries
                  </small>
                </div>

                <div style="display: flex; justify-content: flex-end">
                  <base-pagination
                    class="pagination-no-border"
                    :current="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    @change="paginationChanged($event)"
                  >
                  </base-pagination>
                </div>
              </section>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Loading Screen -->
    <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative">
                <img src="img/brand/img-logo-color.png" alt="" />
                <img
                  src="img/brand/klhk.png"
                  alt=""
                  style="
                    position: absolute;
                    right: 0;
                    top: -200px;
                    opacity: 0.2;
                  "
                />
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal PDF -->
    <Modal
      :show="showDownloadModal"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModal = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="height: 500px; overflow: scroll">
            <div id="printBos">
              <div v-if="isLoading" class="text-center" style="margin-top: 200px;"><h1>Sedang Menyiapkan Data ...</h1></div>
              <div v-else id="headerpdf">
                <div style="display: flex; justify-content: space-between">
                  <div>
                    <div style="display: flex;justify-content: center;align-items: center;">
                      <div style="width: 50px; height: 50px">
                        <img src="/img/brand/img-logo-color.png" alt="" style="width: 100%"/>
                      </div>
                      <h3 class="ml-3">Kementerian Lingkungan Hidup</h3>
                    </div>
                  </div>
                  <div>
                    <!-- <h2>{{ pdfName }}</h2> -->
                    <small><b>Laporan Perjam</b></small
                    ><br />
                  </div>
                </div>
                <table class="table mt-3">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white">No</th>
                      <th class="text-white">Nama Industri</th>
                      <th class="text-white">Waktu</th>
                      <th class="text-white">PH</th>
                      <th class="text-white">COD (MG/L)</th>
                      <th class="text-white">TSS (MG/L)</th>
                      <th class="text-white">NH3N (MG/L)</th>
                      <th class="text-white">DEBIT (MG3/MENIT)</th>
                      <th class="text-white">Beban COD</th>
                      <th class="text-white">Beban TSS</th>
                      <th class="text-white">Beban NH3N</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload">
                      <td>{{idx + 1 }}</td>
                      <td>{{item.comp_name }}</td>
                      <td>{{formatDate(item.last_timestamp) }}</td>
                      <td>{{item.ph ? item.ph.toFixed(5) : "Tidak Diwajibkan" }}</td>
                      <td>{{item.cod ? item.cod.toFixed(5) : "Tidak Diwajibkan"}}</td>
                      <td>{{item.tss ? item.tss.toFixed(5) : "Tidak Diwajibkan"}}</td>
                      <td>{{item.nh3n ? item.nh3n.toFixed(5) : "Tidak Diwajibkan"}}</td>
                      <td>{{item.debit? item.debit.toFixed(5): "Tidak Diwajibkan"}}</td>
                      <td>{{item.beban_cod? item.beban_cod.toFixed(5): "Tidak Diwajibkan"}}</td>
                      <td>{{item.beban_tss? item.beban_tss.toFixed(5): "Tidak Diwajibkan"}}</td>
                      <td>{{item.beban_nhn? item.beban_nhn.toFixed(5): "Tidak Diwajibkan"}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <button class="btn btn-primary" @click="print" :disabled="isLoading">Unduh PDF</button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModal = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>

    <!-- Download Modal Excel -->
    <Modal
      :show="showDownloadModalCSV"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModalCSV = false"
      size="xl"
    >
      <div>
        <div
          id="announcement"
          class="rounded overflow-hidden mdl-contentpopups"
        >
          <div class="p-4" style="height: 500px; overflow: scroll">
            <div>
              <div>
                <div v-if="isLoading" class="text-center" style="margin-top: 200px;"><h1>Sedang Menyiapkan Data ...</h1></div>
                <div v-else style="padding: 10px; overflow-x: scroll">
                  <table class="table mt-3" ref="exportable_table">
                    <thead class="bg-primary">
                      <tr>
                        <th class="text-white">No</th>
                        <th class="text-white" colspan="3">Nama Industri</th>
                        <th class="text-white" colspan="2">Jenis Industri</th>
                        <th class="text-white" colspan="2">Provinsi</th>
                        <th class="text-white" colspan="2">Kabupaten / Kota</th>
                        <th class="text-white" colspan="2">Waktu</th>
                        <th class="text-white" colspan="1">PH</th>
                        <th class="text-white" colspan="1">COD (MG/L)</th>
                        <th class="text-white" colspan="1">TSS (MG/L)</th>
                        <th class="text-white" colspan="1">NH3N (MG/L)</th>
                        <th class="text-white" colspan="1">DEBIT (MG3/MENIT)</th>
                        <th class="text-white" colspan="1">BEBAN COD (KG/JAM)</th>
                        <th class="text-white" colspan="1">BEBAN TSS (KG/JAM)</th>
                        <th class="text-white" colspan="1">BEBAN NH3N (KG/JAM)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, idx) in listDataDownload">
                        <td>{{idx + 1 }}</td>
                        <td colspan="3">{{item.comp_name }}</td>
                        <td colspan="2">{{item.comp_type_name}}</td>
                        <td colspan="2">{{item.province_name}}</td>
                        <td colspan="2">{{item.kabkot_name}}</td>
                        <td colspan="2">{{formatDate(item.last_timestamp) }}</td>
                        <td colspan="1">{{item.ph ? item.ph.toFixed(5) : "Tidak Diwajibkan" }}</td>
                        <td colspan="1">{{item.cod ? item.cod.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.tss ? item.tss.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.nh3n ? item.nh3n.toFixed(5) : "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.debit? item.debit.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.beban_cod? item.beban_cod.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.beban_tss? item.beban_tss.toFixed(5): "Tidak Diwajibkan"}}</td>
                        <td colspan="1">{{item.beban_nhn? item.beban_nhn.toFixed(5): "Tidak Diwajibkan"}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="ExportExcel('xlsx')"
              :disabled="isLoading"
            >
              Unduh Excel
            </base-button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModalCSV = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import API from "../../../api/base_url";
import BaseHeader from "@/components/BaseHeader";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Select, Option, Table, TableColumn } from "element-ui";
import moment from "moment";
// new
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  components: {
    Modal,
    BaseHeader,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mounted() {
    this.getData()
    this.log_as = localStorage.getItem("as");
    this.getCompanyType();
    this.getCompanyName();
    this.getCompliencePoint()
    this.getSelectFormValue();
  },
  data() {
    return {
      noPh:false,
      noCod:false,
      noTss:false,
      noDebit:false,
      noNh3n:false,
      noBebanCod:false,
      noBebanTss:false,
      noBebanNh3n:false,
      pdfName: "",
      listDataDownload: [],
      listDataHeaderDownload: [],
      listDataFooterDownload: [],
      listDataCompliencePoints: [],
      filterStatusPendaftaran: "",
      selectOptionStatus: [
        {
          val: "",
          name: "Semua",
        },
        {
          val: 0,
          name: "Menunggu",
        },
        {
          val: 1,
          name: "Disetujui",
        },
        {
          val: 2,
          name: "Ditolak",
        },
      ],
      userInput: {
        tanggal: "",
        registrationType: "SP",
        titikPenaatan: "",
        companyType: "",
        companyName: "",
        provinsi: [],
        kabupatenKota: [],
        regency: [],
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: false,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: true,
          time_24hr: true,
          plugins: [
            ShortcutButtonsPlugin({
              button: [
                {
                  label: "Data Hari ini",
                  attributes: {
                    class: "btn w-75 btn-dark mb-2 btn-sm mt-2",
                  },
                },
                {
                  label: "Data 1 Minggu",
                  attributes: {
                    class: "btn w-75 btn-dark mb-2 btn-sm mt-2",
                  },
                },
                {
                  label: "Data 1 Bulan",
                  attributes: {
                    class: "btn w-75 btn-dark mb-5 btn-sm mt-2",
                  },
                },
                {
                  label: "Reset Pencarian Tanggal",
                  attributes: {
                    class: "btn w-100 btn-danger btn-sm mt-2",
                  },
                },
              ],
              onClick: (index, fp) => {
                fp.clear();
                if (index == 0) {
                  const startDate = moment().startOf("day");
                  const endDate = moment(startDate).endOf("day");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                } else if (index == 1) {
                  const startDate = moment().startOf("day");
                  const endDate = moment(startDate).add(1, "week").endOf("day");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                } else if (index == 2) {
                  const startDate = moment().startOf("month");
                  const endDate = moment().endOf("month");
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                }else{
                  fp.clear();
                }
              },
            }),
          ], // Gunakan plugin di sini

          defaultHour: 0,
          mode: "range",
        },
        registrationType: [],
        compiencePoint: [],
        companyType: [],
        companyName: [],
        provinsi: [],
        kabupatenKota: [],
        regency: [],
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false,
      showDownloadModal: false,
      showDownloadModalCSV: false,
      // end of filter
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1,
      },
      log_as: "",
      listData: [],
    };
  },
  methods: {
    getData() {
      this.isLoading = true;

      const queryParams = this.queryParams();

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) =>
        value !== undefined && value !== null && value !== ""
        )
      );

      console.log("yuhuuuuu");

      API.get(`laporan-pengiriman`, { params, headers })
        .then(({ data }) => {
          if (this.isEmptyObject(data.data)) {
            this.listData = [];
          } else {
            this.listData = data.data.data;
            this.checkSensorRegistered(data.data.data[0])
            this.pagination.total = data.meta.pagination.total_items;
          }
        
          this.isLoading = false;
        })
        .catch((err) => {
          Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
          this.isLoading = false
        });
    },
    checkSensorRegistered(data) {

      if (data.ph == undefined) {
        this.noCod = true
      }

      if (data.tss == undefined) {
        this.noTss = true
      }

      if (data.nh3n == undefined) {
        this.noNh3n = true
      }

      if (data.debit == undefined) {
        this.noDebit = true
      }

      if (data.cod == undefined) {
        this.noCod = true
      }

      if (data.beban_cod == undefined) {
        this.noBebanCod = true
      }

      if (data.beban_nh3n == undefined) {
        this.noBebanNh3n = true
      }

      if (data.beban_tss == undefined) {
        this.noBebanTss = true
      }


    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    companyTypeChange() {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      this.form.umum.compProvince = ""
      this.userInput.companyName = ""
      this.userInput.titikPenaatan = ""
      this.listDataCompliencePoints = ""
    },
    getDataDownload() {
      this.isLoading = true;

      let tmpDate = this.userInput.tanggal.split("to");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      const queryParams = {
        type:"hourly",
        // page: 1,
        // page_size: 100,
        status: this.filterStatusPendaftaran,
        // is_export: true,
        start_date: startDate,
        end_date: endDate,
        province_id: this.form.umum.compProvince,
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };

      const params = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ""
        )
      );

      API.get(`laporan-pengiriman`, { params, headers })
        .then(({ data }) => {
          if (this.isEmptyObject(data)) {
            this.listDataDownload = [];
            this.isLoading = false;
          } else {
            this.listDataDownload = data.data.data;
            this.listDataHeaderDownload = data.data.header;
            this.listDataFooterDownload = data.data.footer;
            this.pagination.total = data.meta.pagination.total_items;
            setTimeout(() => {
              this.isLoading = false;
            }, 200);
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    downloadPDFNow() {
      if (!this.userInput.titikPenaatan) {
       return Swal.fire('Anda harus memilih titik penaatan','pilih titik penaatan yang ingin di unduh','error') 
      }

      this.getDataDownload();
      this.showDownloadModal = true;
    },
    downloadExcelNow() {
      if (!this.userInput.titikPenaatan) {
       return Swal.fire('Anda harus memilih titik penaatan','pilih titik penaatan yang ingin di unduh','error') 
      }
    
      this.getDataDownload();
      this.showDownloadModalCSV = true;
    },
    // last
 
    getCompanyType() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get("comp-types?page=1&page_size=100", { headers })
        .then(({ data: content }) => {
          this.provideSelect.companyType = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanyName() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      let tmpDate = this.userInput.tanggal.split("to");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }


      const queryParams = {
        type_id:this.userInput.companyType,
        prov_id: this.form.umum.compProvince,
        kabkot_id: this.form.umum.compCity,
      };

      const params = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ""
        )
      );


      API.get("comps", { params,headers })
        .then(({ data: content }) => {
          this.provideSelect.companyName = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSelectFormValue() {
      API.get(`provinces?page=1&page_size=100`)
        .then(({ data: content }) => {
          this.provideSelect.provinsi = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCityDepentOnProvince(data) {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      this.userInput.companyName =""
      this.userInput.titikPenaatan =""

      this.getCompanyName();
      API.get(`kabkots?prov_id=${data}`)
        // API.get(`region/regencies/province/${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(`stations`, { headers })
        .then(({ data: content }) => {
          this.listDataCompliencePoints = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMyCompliencePoint() {
      this.userInput.titikPenaatan =''
      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      API.get(
        `stations?comp_id=${this.userInput.companyName}`,
        { headers }
      )
        .then(({ data: content }) => {
          this.listDataCompliencePoints = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBaseOnStatus() {
      this.isLoading = true;

      let tmpDate = this.userInput.tanggal.split(" to ");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      const queryParams = this.queryParams();


      let headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };


      const params = Object.fromEntries(
        Object.entries(queryParams).filter(
          ([key, value]) =>
            value !== undefined && value !== null && value !== ""
        )
      );

      API.get(`laporan-pengiriman`, { params, headers })
        .then(({ data }) => {
          if (this.isEmptyObject(data.data)) {
            this.listData = [];
            this.isLoading = false;
          } else {
            this.listData = data.data.data;
            this.checkSensorRegistered(data.data.data[0])
            this.pagination.total = data.meta.pagination.total_items;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          Swal.fire('Gagal mendapatkan data','','error')
        });
    },
    async print() {
      try {
        // Dapatkan data dari objek
        const data = this.listDataDownload;
        const defaultSensorValues = [
          { sensor_type: 'pH', bmal_total: 0, bmal_percentage: 0 },
          { sensor_type: 'COD', bmal_total: 0, bmal_percentage: 0 },
          { sensor_type: 'TSS', bmal_total: 0, bmal_percentage: 0 },
          { sensor_type: 'NH3N', bmal_total: 0, bmal_percentage: 0 },
          { sensor_type: 'Debit', bmal_total: 0, bmal_percentage: 0 }
        ];
        const data_header = this.listDataHeaderDownload || [];
        const data_footer = this.listDataFooterDownload || defaultSensorValues;

        
        const convertedFooter = data_footer.map((item, index) => {
            return [
                index + 1,                           // Nomor urut
                item.sensor_type,                   // Tipe sensor
                item.bmal_total !== null ? item.bmal_total : 0, // Jumlah BMAL total
                item.bmal_percentage !== null ? item.bmal_percentage : 'Tidak Diwajibkan' // Persentase BMAL atau "Tidak Diwajibkan" jika 0
            ];
        });



        // Buat dokumen jsPDF
        const doc = new jsPDF();

        // Tambahkan header
        const header = [
          "No",
          "Nama Industri",
          "Waktu",
          "PH",
          "COD (MG/L)",
          "TSS (MG/L)",
          "NH3N (MG/L)",
          "DEBIT (MG3/MENIT)",
        ];

        let headerPemenuhan = [
                "No",
                "Pemantauan terus menerus dalam jaringan",
                "Jumlah Data yang Memenuhi Baku Mutu Air Limbah",
                "Presentase Pemenuhan Baku Mutu Air Limbah"
        ];

        let compName = ""
        let compAddress = ""
        let compLongitude = ""
        let compLatitude = ""
        let compTlp = ""
        let userEmail = ""
        let compType = ""
        let compSumberLimbah = ""
        let compTeknologi = ""
        let compNoIzin = ""
        let compInstansi = ""
        let compTglIzin = ""
        let compStation = ""

        
        // Tambahkan baris data
        const rows = data.map((item, index) =>
          [
          index + 1,
          item.comp_name,
          this.formatDate(item.last_timestamp),
          item.ph ? item.ph.toFixed(5) : "Tidak Diwajibkan",
          item.cod ? item.cod.toFixed(5) : "Tidak Diwajibkan",
          item.tss ? item.tss.toFixed(5) : "Tidak Diwajibkan",
          item.nh3n ? item.nh3n.toFixed(5) : "Tidak Diwajibkan",
          item.debit ? item.debit.toFixed(5) : "Tidak Diwajibkan",
        ]);

        // data_header.map((item,index)=>{
          compName = data_header.comp_name
          compAddress = data_header.comp_address
          compLongitude = data_header.longitude
          compLatitude = data_header.latitude
          compTlp = data_header.comp_phone
          userEmail = "data_header.user_email"
          compType = data_header.comp_type_name
          compSumberLimbah = data_header.waste_source
          compTeknologi = data_header.processing_technique
          compNoIzin = data_header.permit_no
          compInstansi = data_header.permit_issuing_authority
          compTglIzin = data_header.permit_publication_date
          compStation = "data_header.station"
        // })


        let bodyProfile = [
                ["NAMA INDUSTRI", compName],
                ["ALAMAT", compAddress],
                ["LATITUDE", compLongitude],
                ["LONGITUDE", compLatitude],
                ["NOMOR TELEPON", compTlp],
                ["EMAIL", userEmail],
                ["JENIS USAHA", compType],
                ["SUMBER LIMBAH", compSumberLimbah],
                ["TEKNOLOGI PENGOLAHAN LIMBAH", compTeknologi],
                ["NO. IZIN", compNoIzin],
                ["INSTANSI PENERBIT", compInstansi],
                ["TANGGAL TERBIT IZIN", compTglIzin]
        ];

       
        
        const logo = new Image();
        logo.src = '/img/brand/img-logo-color.png';

        // Tambahkan informasi profil di atas judul
        doc.addImage(logo, 'JPEG', 10, 10, 20, 20);
        doc.text("Kementerian Lingkungan Hidup", 35, 20, { lineHeight: 15 }); // Sesuaikan nilai lineHeight sesuai kebutuhan

        // Tambahkan judul
        doc.setFontSize(11);
        doc.text("Laporan - Perjam", 130, 20);
        // doc.text(compStation, 130, 35);
        doc.setTextColor(90, 90, 90)
        doc.setFont("bold");
        doc.text(`Data ini dibuat pada ${moment().format('DD-MM-YYYY HH:mm')}`, 130, 26);
        doc.setFont("normal");

      
        doc.autoTable({
          theme: "plain",
          body: bodyProfile,
          startY: 45, // Mulai di bawah judul profil
          columnStyles: {
            0: {
              fontStyle: "bold",
              halign: "left",
              cellWidth: 70,
              fillColor: [246, 249, 252],
            },
            1: { cellWidth: 100 },
          },
          styles: {
            cellPadding: 2,
            textColor: [41, 102, 75],
            // font: "times",
            lineWidth: 0.1,
            lineColor: [240, 240, 240],
            halign: "left",
          },
        });

        // Tambahkan tabel data di bawah informasi profil
        doc.autoTable({
          head: [header],
          theme: "striped",
          body: rows,
          headStyles: {
            fillColor: [41, 102, 75],
          },
          styles: {
            lineWidth: 0.2,
            halign: "center",
          },
        });

        doc.addPage();

          doc.autoTable({
                  head: [headerPemenuhan],
                  theme: "striped",
                  body: convertedFooter,
                  headStyles: {
                    fillColor: [41, 102, 75]
                  },
                  styles: {
                    lineWidth: 0.2,
                    halign: "center"
                  },
          });

        // Simpan dokumen PDF
        doc.save("exportData.pdf");

        Swal.fire("Data di Unduh", "", "success");
        this.showDownloadModal = false;
      } catch (error) {
        console.error("Error:", error);
        // Tambahkan penanganan kesalahan jika diperlukan
      }
    },
    ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      Swal.fire("Data di Unduh", "", "success");
      this.showDownloadModalCSV = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || "SheetJSTableExport." + (type || "xlsx"));
    },
    reportTableState() {
      return [];
    },
    // End of Filter
    
    formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm');
      return humanDate;
    },
    changeSizePage() {
      this.getData();
    },
    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.getData();
    },
    queryParams(){
      let tmpDate = this.userInput.tanggal.split(" to ");

      let startDate = tmpDate[0];
      let endDate = tmpDate[1];

      if (endDate == undefined) {
        endDate = startDate;
      } else if (endDate) {
        endDate.trim();
        startDate.trim();
      }

      const queryParams = {
        type:"hourly",
        page: this.pagination.currentPage,
        page_size: this.pagination.perPage,
        status: this.filterStatusPendaftaran,
        province_id: this.form.umum.compProvince,
        start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
        kabkot_id: this.form.umum.compCity,
        comp_id: this.userInput.companyName,
        comp_type_id: this.userInput.companyType,
        station_id: this.userInput.titikPenaatan,
      };
      return queryParams
    },
    kabkotChange(){
      this.getCompanyName();
    }
  },
};
</script>

